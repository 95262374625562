<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template> -->
      <!-- แก้ไขอีเมล -->
      <v-card v-if="key_email === 'e'">
        <v-card-title>{{ $t("adminsetemail.headereditemail") }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <br />
          <!-- <v-icon>mdi-email-plus</v-icon> -->
          <!-- <v-text-field
                prepend-icon="mdi-email-plus"
                label="E-mail"
                outlined
                :color="color.theme"
                dense
            ></v-text-field> -->
          <v-text-field
            prepend-icon="contact_mail"
            dense
            outlined
            label="E-mail"
            v-model="email_name"
            :disabled="createprogress_e"
            :error-messages="email_nameError"
            required
            @input="$v.email_name.$touch()"
            @blur="$v.email_name.$touch()"
            :color="color.theme"
          ></v-text-field>
          <!-- <v-btn @click="openeditemail()">เพิ่มอีเมล</v-btn> -->
        </v-card-text>
        <!-- <v-card-text>
          <br> 
          <v-text-field :color="color.theme" prepend-icon="mdi-file-settings-outline" dense outlined :label="$t('changeName.filename')" v-model="filename" :disabled="createprogress" :error-messages="foldernameErrors" required @input="$v.filename.$touch()" @blur="$v.filename.$touch()"></v-text-field>
        </v-card-text> -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('close'), cleardata()">{{
            $t("changeName.close")
          }}</v-btn>
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="fn_add_email()"
            :loading="add_emailprogress"
            >{{ $t("adminsetemail.edit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
      <!-- เพิ่มอีเมล -->
      <v-card v-else>
        <v-card-title>{{ $t("adminsetemail.headeraddemail") }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <br />
          <v-text-field
            prepend-icon="contact_mail"
            dense
            outlined
            label="E-mail"
            v-model="mail"
            :disabled="createprogress"
            :error-messages="mailError"
            required
            @input="$v.mail.$touch()"
            @blur="$v.mail.$touch()"
            :color="color.theme"
          ></v-text-field>
          <!-- <v-btn @click="openeditemail()">เพิ่มอีเมล</v-btn> -->
        </v-card-text>
        <!-- <v-card-text>
          <br> 
          <v-text-field :color="color.theme" prepend-icon="mdi-file-settings-outline" dense outlined :label="$t('changeName.filename')" v-model="filename" :disabled="createprogress" :error-messages="foldernameErrors" required @input="$v.filename.$touch()" @blur="$v.filename.$touch()"></v-text-field>
        </v-card-text> -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('close'), cleardata()">{{
            $t("changeName.close")
          }}</v-btn>
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="fn_add_email()"
            :loading="add_emailprogress"
            >{{ $t("adminsetemail.add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    email_name: { required, email },
    mail: { required, email },
  },
  props: ["show", "email_all", "key_email", "email"],
  data: function() {
    return {
      add_emailprogress: false,
      mail: "",
      email_name: "",
      payload_email: [],
      createprogress: false,
      createprogress_e: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    mailError() {
      const errors = [];
      if (!this.$v.mail.$dirty) return errors;
      !this.$v.mail.required &&
        errors.push(this.$t("sendtoemail.pleasefillemail"));
      !this.$v.mail.email &&
        errors.push(this.$t("sendtoemail.invalidemailformat"));
      return errors;
    },
    email_nameError() {
      const errors = [];
      if (!this.$v.email_name.$dirty) return errors;
      !this.$v.email_name.required &&
        errors.push(this.$t("sendtoemail.pleasefillemail"));
      !this.$v.email_name.email &&
        errors.push(this.$t("sendtoemail.invalidemailformat"));
      return errors;
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        console.log("email_all", this.email_all);

        this.email_name = this.email;
        console.log(this.email_name);
      } else {
      }
    },
  },
  methods: {
    //เคลียร์ค่า
    cleardata() {
      this.$v.$reset();
      this.createprogress = false;
      this.createprogress_e = false;
      this.mail = "";
      this.email_name = "";
    },
    changeobject() {
      console.log(this.email_all);
      console.log(this.email);
      this.payload_email = [];
      for (let i = 0; i < this.email_all.length; i++) {
        if (this.key_email === "e") {
          if (this.email !== this.email_all[i]["email"]) {
            this.payload_email.push(this.email_all[i]["email"]);
          }
        } else {
          this.payload_email.push(this.email_all[i]["email"]);
        }
      }
      console.log(this.payload_email);
    },
    //เพิ่มอีเมล
    async fn_add_email() {
      if (
        this.mail !== "" ||
        (this.email_name !== "" && this.email_name !== undefined)
      ) {
        this.changeobject();
        this.add_emailprogress = true;
        if (this.key_email === "e") {
          this.payload_email.push(this.email_name);
        } else {
          this.payload_email.push(this.mail);
        }
        var payload = {
          business_id: this.dataAccountActive.business_info.business_id,
          email_alert: this.payload_email,
        };
        console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/email_alert_storage",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              Toast.fire({
                icon: "success",
                title: response.data.result.Message,
              });
              this.add_emailprogress = false;
              this.cleardata();
              this.$emit("closereload");
            } else {
              Toast.fire({
                icon: "error",
                title: "error",
              });
            }
          })
          .catch((error) => {
            // console.log(error);
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          });
      } else {
        Toast.fire({
          icon: "error",
          title: "กรุณากรอกอีเมล",
        });
      }
    },
  },
};
</script>
